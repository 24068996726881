/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import './Layout.scss'

const Layout = ({ children }) => {
  // If the user hits the back button while a Section Hero is loading, the scrollbars could disappear. This fixes that.
  useEffect(() => {
    document.body.style.overflowY = 'unset'
  }, [])

  // Get the scrollbar width, and set a css variable. The variable is used in Layout.module.scss to set body width
  useEffect(() => {
    let scroller = document.scrollingElement
    // Width of the scrollbar
    scroller.style.setProperty(
      '--scrollbar-width',
      window.innerWidth - scroller.clientWidth
    )
  }, [])

  return (
    <main>
      {children}
      <footer className={`backgroundBlack textCenter pt32 pb16`}>
        &copy; {new Date().getFullYear()} Filipino Veterans Recognition and
        Education Project. All Rights Reserved.
      </footer>
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
